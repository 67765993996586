var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column align-center" }, [
    _c("div", { staticClass: "textDark-text" }, [
      _vm._v(
        " Follow the instructions in the Trezor connection tab. If it did not open automatically, click below. "
      ),
    ]),
    _c(
      "div",
      [
        _c("mew-button", {
          staticClass: "mt-7",
          attrs: {
            title: "Connect Trezor",
            icon: "mdi-open-in-new",
            "icon-type": "mdi",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.trezorUnlock.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "greenPrimary--text my-8 cursor--pointer",
        on: { click: _vm.reset },
      },
      [
        _c(
          "v-icon",
          { staticClass: "greenPrimary--text", attrs: { small: "" } },
          [_vm._v("mdi-arrow-left")]
        ),
        _vm._v(" Connect a different wallet "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }